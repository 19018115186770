// components/Login.js
import React, { useState } from 'react';
import { login } from '../services/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    
const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await login(username, password);

        if (token) {
            localStorage.setItem('token', token);
            navigate('/protected');
        } else {
            // Handle login error
        }
    };

    return (
        <div>
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Username:
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </label>
            <br />
            <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </label>
            <br />
            <button type="submit">Login</button>
          </form>
        </div>
      );
    };
    
    export default Login;