// App.js
// Version 1
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Protected from './components/Protected';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/protected" element={<Protected />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App; // For default export


// // Version 2

// import React, { useEffect } from 'react';
// import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
// import Login from './components/Login';
// import Protected from './components/Protected';
// import { login } from './yourLoginApi'; // Import your login function

// function App() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const checkToken = async () => {
//       // Check if a valid token exists in localStorage
//       const storedToken = localStorage.getItem('token');

//       if (storedToken) {
//         // You might want to add logic here to validate the token on the server
//         // For simplicity, we'll assume the token is always valid for this example
//         navigate('/protected');
//       }
//     };

//     checkToken();
//   }, [navigate]);

//   const handleLogin = async (username, password) => {
//     const token = await login(username, password);

//     if (token) {
//       // Login successful, redirect to /protected
//       navigate('/protected');
//     }
//   };

//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<Login onLogin={handleLogin} />} />
//         <Route path="/protected" element={<Protected />} />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;
