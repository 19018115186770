// services/auth.js
import axios from 'axios';

const apiUrl = 'https://hello-world.hairylime.net'; // Replace with your API URL

export const login = async (username, password) => {
  try {
    console.log('login', username, password)
    const response = await axios.post(`${apiUrl}/login`, { username, password });
    
console.log(response.data.token);

        // Save the token in localStorage
        localStorage.setItem('token', response.data.token);
    
    return response.data.token;
  } catch (error) {
    console.error('Login error:', error);
    return null;
  }
};

export const register = async (username, password) => {
  try {
    const response = await axios.post(`${apiUrl}/register`, { username, password });
    return response.data.message;
  } catch (error) {
    console.error('Registration error:', error);
    return null;
  }
};

export const logout = () => {
  // You can perform any additional logout logic here if needed
  localStorage.removeItem('token');
};

export const getProtectedData = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token available');
    }

    const response = await axios.get(`${apiUrl}/protected`, {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error('Protected data fetch error:', error);
    throw error; // Propagate the error for handling in components
  }
};
