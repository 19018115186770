// components/Protected.js
import React, { useEffect, useState } from 'react';
//import { jwtDecode } from 'jsonwebtoken';
import jwtDecode from 'jsonwebtoken/decode';
import { useNavigate } from 'react-router-dom';

const Protected = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login if not authenticated
      navigate('/login');
    } else {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded);
      } catch (error) {
        console.error('Error decoding JWT:', error.message);
        // Handle token decoding error, e.g., logout the user
        localStorage.removeItem('token');
        navigate('/login');
      }
    }
  }, [navigate]);

  // ... protected content
  return (
    <div>
      <h2>Protected Content</h2>
      {user && (
        <div>
          <p>Welcome, {user.username}!</p>
          {/* Render additional protected content here */}
        </div>
      )}
    </div>
  );
};

export default Protected;
